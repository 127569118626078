.full-screen {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.transfer_container{
	position: absolute;
	top: 50%;
	z-index: 2;
	height: 375px;
	left: 100px;
	margin: -187px 0px 0px 0px;
	border-radius: 14px;
	background-color: #000000;
	color: #ffffff;
	box-shadow: 0 0 30px rgba(0,0,0,0.75);
}

#mycarousel{
    background-color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}