/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 18-apr-2016, 15.07.59
    Author     : Proteinic
*/

html, body {
    font-family: 'Roboto', sans-serif;
}
.pt-panel {
    position: absolute;
    background-color: #fff;
    top: 50%;
    left: 70px;
    margin-top: -190px;
    border-radius: 15px;
    width: 250px;
    min-height: 350px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    z-index: 10;
}
.pt-panel-black {
    background-color: #111111;
    color: #ffffff;
    border-radius: 15px;
}
.pt-login {
}
.panel {
    background: none;
    border: 0;
}
.panel-default > .panel-heading, .panel-heading {
    background: none;
    border: 0 none;
    color: #fff;
    font-size: 26px;
    letter-spacing: -0.5px;
}
.panel-heading small {
    font-size: 14px;
    font-weight: normal;
}
.pt-login .form-group {
    margin-bottom: 10px;
}
.pt-panel .btn {
    padding: 4px 12px;
    outline: none;
}
.pt-panel .btn-primary {
    width: 100%;
    background-color: #78c336;
    border: 0;
    font-size: 18px;
    text-transform: uppercase;
    border-radius: 20px;
}
.pt-login a {
    display: block;
    color: #999;
    font-size: 12px;
}
.pt-login hr {
    border-color: #333;
    margin: 10px 0;
}
.pt-uploader {
}
.pt-uploader form {
    padding: 0 15px;
}
.multiple_emails-container {
    background-color: #fff;
    color: #555;
}
.multiple_emails-container input {
    
}
.multiple_emails-container ul {
    margin: 0;
}
.pt-uploader button {
    margin-bottom: 15px;
}
.container-uploader {
    overflow: hidden;
    left: 250px;
    position: absolute !important;
    top: 20px;
    transition: width 300ms;
    width: 0;
}
.container-uploader.open {
    width: 500px;
}
#html5_uploader {
    background-color: #fff;
    width: 500px;
    color: #555;
    border-radius: 0 15px 15px 0;
}
.carica-files {
    float: left;
    font-size: 12px !important;
    width: 50% !important;
}
.scadenza {
    float: left;
    width: 50%;
    margin: 5px 0;
    text-align: right;
}
.scadenza label {
    font-size: 12px;
}
.scadenza select {
    color: #555;
    border: 0;
}
.form-control {
    font-size: 13px;
}
.multiple_emails-container ul {
    overflow-x: hidden;
    max-height: 160px;
}
.multiple_emails-email {
    width: -moz-max-content;
    width: -webkit-max-content;
    width: max-content;
}
.pt-downloader {
    text-align: center;
}
.pt-downloader ul, .pt-downloader ul li {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #999;
}
.pt-downloader ul {
    max-height: 140px;
    overflow-y: auto;
}
.pt-downloader h4 {
    margin: 20px auto;
}
.menu {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    text-align: center;
}
.menu > li {
    display: inline-block;
}
.menu > li:not(:last-child):after {
    content: "|";
    color: #ddd;
}
.menu > li > a {
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 8px;
    color: #999;
}
.logo {
    bottom: 0;
    height: 200px;
    margin: auto;
    position: absolute;
    right: 50px;
    top: 0;
    width: 350px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
	z-index: 9999;
}
ul{
	list-style:none;
	margin: 0px;
	padding: 0px;
}
#form-errors, div.has-error .help-block {
	font-size: 13px;
}
#form-errors li{
	padding: 2px 0px;
}
.links {
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 11px;
    text-transform: uppercase;
	z-index: 999;
}
.links ul li {
    float: left;
}
.links ul li a {
    display: block;
    padding: 5px 15px;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
    background-color: rgba(255,255,255,0.9);
    border-radius: 5px;
    margin-right: 8px;
}
.links ul li:last-child a {
    margin-right: 0;
}
.slides .is_link{
	cursor: pointer; cursor: hand;
}
.slides > li > div.testo-slide {
    height: 380px;
    position: absolute;
    top: 0;
	bottom: 0;
    left: 350px;
    margin: auto;
	z-index: 9999;
    width: calc(100% - 380px);
}
.slides > li > div.testo-slide h1, 
.slides > li > div.testo-slide h2, 
.slides > li > div.testo-slide h3, 
.slides > li > div.testo-slide h4, 
.slides > li > div.testo-slide h5, 
.slides > li > div.testo-slide h6 {
    margin: 0;
    margin-bottom: 10px;
}